import React from 'react'
import useInView from "react-cool-inview";
const { upperFirst } = require('lodash')

const ProjectListItem = ({ article }) => {
    const { observe, inView } = useInView();
    
    if (article) {
        return (
            <div className="c-project-listing__item" ref={observe}>
                <div className={inView ? "c-project-listing__item-wrapper is-inview" : "c-project-listing__item-wrapper"}>
                    <div className="c-project-listing__item-media-container">
                        <div className="c-project-listing__item-media-wrapper">
                            {article.data.image.url ? <img src={`${article.data.image.url}&w=500`} alt={article.data.image.alt ? article.data.image.alt : article.data.title.text} className="c-project-listing__item-media" loading="lazy"/> : <img src="https://picsum.photos/320/180" alt="" className="c-project-listing__item-media" loading="lazy"/> }
                        </div>
                        {article.tags.length ? <div className="c-project-listing__item-category">{upperFirst(article.tags[0])}</div> : null}
                    </div>
                    <h3 className="c-project-listing__item-title">{article.data.title.text}</h3>
                    <p className="c-project-listing__item-summary">{article.data.summary.text}</p>
                 </div>
            </div>
        )
    } 
    
    return null
}

export default ProjectListItem