
import * as React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

// Create URL path for numeric pagination
const getPageNumberPath = (currentIndex, basePath) => {
  if (currentIndex === 0) {
    return basePath
  }
  
  return `${basePath}/page-${(currentIndex + 1)}`
}

const getPaginationGroup = (currentPage, pageCount) => {
    const pageLimit = 5 // Number of page number to show.

    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    return new Array(pageLimit).fill().map((_, idx) => start + idx + 1).splice(0, pageCount);
};

export const Pagination = ({ pageInfo, basePath }) => {
    if (!pageInfo) 
        return null

    const { currentPage, pageCount } = pageInfo

    // Create URL path for previous and next buttons
    const prevPagePath = currentPage === 2 ? basePath : `${basePath}/page-${(currentPage - 1)}`
    const nextPagePath = `${basePath}/page-${(currentPage + 1)}`
    
    if (pageCount > 1) { 
        return (
            <nav className="c-pagination" aria-label="Pagination">
                <div className="container">
                    <div className="row">
                        <span className="u-visually-hide" id="label-pagination">Pagination</span>
                        <div className="c-pagination__container" aria-labelledby="label-pagination">
                            <ol className="c-pagination__items">
                                {currentPage > 1 ? 
                                <li className="c-pagination__item c-pagination__item--prev">
                                    <Link to={prevPagePath}className="c-pagination__link">
                                        <svg fill="none" viewBox="0 0 20 20" className="c-pagination__link-icon"><path fillRule="evenodd" clipRule="evenodd" d="M9.593 0 11 1.467 2.813 10 11 18.533 9.593 20 0 10.001 9.593 0Z" fill="#1A1A1A"/><path fill="#1A1A1A" d="M20 9v2H2V9z"/></svg>
                                        <span className="u-visually-hide">Go to</span>
                                        <span className="c-pagination__link-text"> Previous </span>
                                        <span className="u-visually-hide">page</span>
                                    </Link>
                                </li> : null}       
                                {getPaginationGroup(currentPage, pageCount).map((item, i) => {
                                    return (
                                        <li key={i} className="c-pagination__item">
                                            <Link to={getPageNumberPath(i, basePath)} className={`c-pagination__link ${currentPage === item ?  "is-current" : ""}`}>
                                                <span className="u-visually-hide">Go to page</span> {item}
                                            </Link>
                                        </li>
                                    )
                                })}
                                {currentPage !== pageCount ?
                                    <li className="c-pagination__item c-pagination__item--next">
                                        <Link to={nextPagePath} className="c-pagination__link">
                                            <span className="u-visually-hide">Go to</span>
                                            <span className="c-pagination__link-text"> Next </span>
                                            <span className="u-visually-hide">page</span>
                                            <svg fill="none" viewBox="0 0 20 20" className="c-pagination__link-icon"><path fillRule="evenodd" clipRule="evenodd" d="M10.407 20 9 18.533 17.187 10 9 1.467 10.407 0 20 9.999 10.407 20Z" fill="#1A1A1A"/><path fill="#1A1A1A" d="M0 11V9h18v2z"/></svg>
                                        </Link>
                                    </li> : null}
                            </ol>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
    else {
        return null
    }
  }

Pagination.propTypes = {
    pageInfo: PropTypes.object,
    basePath: PropTypes.string
}

export default Pagination;